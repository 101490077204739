import { styled } from 'styled-components';
import { Colors, hexToRGB } from '../../../../play-core/styles/Colors';
import { topicColors } from '../../../../play-core/styles/topic-colors';
import { PlayContainer } from '../../components/PlayContainer/PlayContainer';
const paddingTop = '34px';
export const Container = styled(PlayContainer).withConfig({
  displayName: "TopicPagestyles__Container",
  componentId: "sc-1s974u8-0"
})(["padding-top:", ";position:relative;&:before{margin-top:-", ";z-index:-1;width:100%;height:572px;position:absolute;content:'';background:", ";}"], paddingTop, paddingTop, ({
  topicUrn
}) => getTopicBackground(topicUrn, 0.7));
export const getTopicBackground = (topicUrn, radialOpacity) => {
  if (topicColors[topicUrn]) {
    const {
      firstColor,
      secondColor
    } = topicColors[topicUrn];
    return `linear-gradient(to bottom, ${hexToRGB(Colors.BLACK, 0)}, ${Colors.GREY_1a1a1a}), radial-gradient(circle at 50% 0, ${hexToRGB(firstColor, radialOpacity)}, ${hexToRGB(secondColor, radialOpacity)} 80%)`;
  }
  return 'transparent';
};